import { template as template_0f8e345d5c7f4aeebf8148db37d882fe } from "@ember/template-compiler";
const FKText = template_0f8e345d5c7f4aeebf8148db37d882fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
