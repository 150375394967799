import { template as template_499a726762cd4b9fa9c69d17b8eaacc8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_499a726762cd4b9fa9c69d17b8eaacc8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
